import React from 'react';

import classes from './Button.module.css';

const button = (props) => (
    <button
        className={[classes.Button, classes[props.btnType], props.className].join(' ')}
        disabled={props.disabled}
        onClick={props.clicked}
        tabIndex="-1">{props.children}</button>
);

export default button;