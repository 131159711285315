import { combineReducers } from "redux";
import authReducer from './auth';
import productsReducer from './products';
import storesReducer from './stores';
import adminReducer from './admin';
import imagesReducer from './images';
import metaDataReducer from './meta-data';
import staffReducer from './staff';
import emailsReducer from './emails';
import availabilityReducer from './availability';

export const rootReducer = combineReducers({
    auth: authReducer,
    products: productsReducer,
    admin: adminReducer,
    stores: storesReducer,
    images: imagesReducer,
    metadata: metaDataReducer,
    staff: staffReducer,
    emails: emailsReducer,
    availability: availabilityReducer
});
