import * as actionTypes from './actionTypes';
import axios from '../../axios-firebase-DB';
import { extractBasicDataItems, extractSubItemDataItems } from './index';
import _ from 'lodash';
import * as firebase from 'firebase';

export const fetchLocationsSuccess = (locations, partial) => {
    return {
        type: actionTypes.FETCH_LOCATIONS_SUCCESS,
        isPartial: partial,
        locations: _.orderBy(locations, 'name')
    };
};

export const fetchLocationsFail = (error) => {
    return {
        type: actionTypes.FETCH_LOCATIONS_FAIL,
        error: error
    };
};

export const fetchLocationsStart = (partial) => {
    return {
        type: actionTypes.FETCH_LOCATIONS_START,
        isPartial: partial
    };
};

export const fetchLocations = (city, startAt, endAt) => {
    if (city) {
        return dispatch => {
            dispatch(fetchLocationsStart(true));
            axios.get(startAt && !endAt ? `/stores/locations/${city}.json?orderBy="$value"&limitToFirst=${startAt}` : startAt && endAt ? `/stores/locations/${city}.json?orderBy="$value"&limitToLast=${endAt - startAt}` : `/stores/locations/${city}.json`)
                .then(res => {
                    const data = extractBasicDataItems(res.data).map((location) => ({
                        ...location,
                        parent_id: city                        
                    })); 
                    dispatch(fetchLocationsSuccess(data, !!city));
                })
                .catch(err => {
                    dispatch(fetchLocationsFail(err));
                });
        } 
    }

    return dispatch => {
        dispatch(fetchLocationsStart(false));
        axios.get(`/stores/locations.json`)
            .then(res => {
                dispatch(fetchLocationsSuccess(extractSubItemDataItems(res.data)));
            })
            .catch(err => {
                dispatch(fetchLocationsFail(err));
            });
    }
};

export const fetchCitiesSuccess = (cities) => {
    return {
        type: actionTypes.FETCH_CITIES_SUCCESS,
        cities: _.orderBy(cities, 'name')
    };
};

export const fetchCitiesFail = (error) => {
    return {
        type: actionTypes.FETCH_CITIES_FAIL,
        error: error
    };
};

export const fetchCitiesStart = () => {
    return {
        type: actionTypes.FETCH_CITIES_START
    };
};

export const fetchCities = () => {
    return dispatch => {
        dispatch(fetchCitiesStart());
        axios.get('/stores/cities.json')
            .then(res => {
                dispatch(fetchCitiesSuccess(extractBasicDataItems(res.data)));
            })
            .catch(err => {
                dispatch(fetchCitiesFail(err));
            });
    }
};

export const fetchAreasSuccess = (areas) => {
    return {
        type: actionTypes.FETCH_AREAS_SUCCESS,
        areas: _.orderBy(areas, 'name')
    };
};

export const fetchAreasFail = (error) => {
    return {
        type: actionTypes.FETCH_AREAS_FAIL,
        error: error
    };
};

export const fetchAreasStart = () => {
    return {
        type: actionTypes.FETCH_AREAS_START
    };
};

export const fetchAreas = () => {
    return dispatch => {
        dispatch(fetchAreasStart());
        axios.get('/stores/areas.json')
            .then(res => {
                const areasList = res.data.map((area, index) => {
                    return {
                        code: index,
                        name: area.name,
                        geolocation: area.geolocation
                    }
                });
                dispatch(fetchAreasSuccess(areasList));
            })
            .catch(err => {
                dispatch(fetchAreasFail(err));
            });
    }
};

export const selectArea = (selectedArea) => {
    return {
        type: actionTypes.SELECT_AREA,
        selectedArea: selectedArea
    };
};

export const setSelectedArea = (areaId) => {
    return dispatch => {
        sessionStorage.setItem("selectedArea", areaId);
        dispatch(selectArea(areaId));
    }
}

export const fetchAreasToSelecting = () => {
    return dispatch => {
        const areaId = sessionStorage.getItem("selectedArea");
        if(areaId){
            dispatch(setSelectedArea(areaId));
        }else{
            dispatch(fetchAreas());
        }
    }
};

export const saveSingleImageLocation = (newImage, location, city, id, locations, token) => {
    return dispatch => {
        dispatch(fetchLocationsStart(true));
        const storageRef = firebase.storage().ref(`images/locations/${city}/${id}-${location.name}`);
        const task = storageRef.put(newImage);
        task.on('state_changed', () => {
        }, (error) => {
            dispatch(fetchLocationsFail(error.message));
        }, () => {
            task.snapshot.ref.getDownloadURL().then((downloadURL) => {
                location.url = downloadURL;
                axios.put(`/stores/locations/${city}/${id}.json?auth=` + token, location)
                .then(response => {
                    dispatch(fetchLocationsSuccess(locations.map(location => location.id === id ? {...location, url: response.data.url} : location)));
                })
                .catch(error => {
                    dispatch(fetchLocationsFail());
                });
            });
        });
    }
}

export const deleteSingleImageLocation = (image, location, city, id, locations, token) => {
    return dispatch => {
        dispatch(fetchLocationsStart(true));
        const storageRef = firebase.storage().refFromURL(image.url);
        storageRef.delete().then(() => {
            axios.put(`/stores/locations/${city}/${id}.json?auth=` + token, location)
                .then(response => {
                    dispatch(fetchLocationsSuccess(locations.map(location => location.id === id ? {...location, url: ''} : location)));
                })
                .catch(error => {
                    dispatch(fetchLocationsFail());
                });
        }).catch((error) => {
            dispatch(fetchLocationsFail(error.message));
        });
    }
}
