import React from 'react';

import classes from './TemporarilyUnavailableScreen.module.css';
import logo from '../../../assets/images/logoSpinnerRemembrance.png';
import backgroundImage from '../../../assets/images/backgroundLightBlue.jpg';
import LazyBackground from '../../../hoc/LazyBackground/LazyBackground';
import LazyImage from '../LazyImage/LazyImage';
import Zoom from 'react-reveal/Zoom';

const TemporarlyUnavailableScreen = (props) => (
    <LazyBackground className={classes.BackgroundImage} src={backgroundImage} loader={true} >
        <Zoom bottom>
            <div className={classes.Frame}>
                <div className={classes.ImageContainer}>
                    <LazyImage alt={'Logo'} className={classes.Logo} src={logo} />
                </div>
                <h1 className={classes.Title}>Bienvenido</h1>
                <h1 className={classes.SubTitle}>En estos momentos estamos mejorando nuestro sitio, volvé en un rato para poder disfrutar de más novedades</h1>
            </div>
        </Zoom>
    </LazyBackground>
);

export default TemporarlyUnavailableScreen;