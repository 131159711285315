import React, { Component } from 'react';
import Spinner from '../../components/UI/Spinner/Spinner';

class LazyBackground extends Component {
    state = { src: null };

    componentDidMount() {
        const { src } = this.props;

        const imageLoader = new Image();
        imageLoader.src = src;

        imageLoader.onload = () => {
            this.setState({ src });
        };
    }

    render() {
        return <div className={this.props.className} style={{ backgroundImage: `url(${this.state.src})` }} onClick={this.props.clicked}>
            {
                this.state.src ?
                    this.props.children
                    : null
            }
            {
                !this.state.src && this.props.loader ?
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: '100%',
                        width: '100%'
                    }}>
                        <Spinner style={{ height: 80, width: 80 }} />
                    </div>
                    : null
            }
        </div>
    }
}

export default LazyBackground;