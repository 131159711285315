import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    availability: null,
    loading: false,
    error: null
};


const fetchAvailabilityStart = (state) => {
    return updateObject(state, {
        loading: true,
        error: null
    });
};

const fetchAvailabilitySuccess = (state, action) => {
    return updateObject(state, {
        availability: action.availability,
        loading: false
    });
};

const fetchAvailabilityFail = (state) => {
    return updateObject(state, { loading: false });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_AVAILABILITY_START:
            return fetchAvailabilityStart(state);

        case actionTypes.FETCH_AVAILABILITY_SUCCESS:
            return fetchAvailabilitySuccess(state, action);

        case actionTypes.FETCH_AVAILABILITY_FAIL:
            return fetchAvailabilityFail(state);

        default:
            return state;
    }
};

export default reducer;