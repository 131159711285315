import React from 'react';

import classes from './NavigationItems.module.css'
import NavigationItem from './NavigationItem/NavigationItem';
import Logo from '../../UI/Logo/Logo';
import loDeMarioLogo from '../../../assets/images/newLogoRemembrance.png';
import narrowLogo from '../../../assets/images/narrowLogoRemembrance.png';
import Zoom from 'react-reveal/Zoom';

const NavigationItems = (props) => {

    let leftItems = [], rightItems = [];
    leftItems = props.menuOptions
        .filter(option => option.onLeft && props.isToolbar)
        .map((option, index) => {
            return (
                <NavigationItem
                    key={index}
                    link={option.link}
                    isToolbar={props.isToolbar}>{option.label}</NavigationItem>
            );
        });

    rightItems = props.menuOptions
        .filter(option => !(option.onLeft && props.isToolbar))
        .map((option, index) => {
            return (
                <NavigationItem
                    key={index}
                    link={option.link}
                    isToolbar={props.isToolbar}>{option.label}</NavigationItem>
            );
        });

    let leftItemsClasses = ["nav navbar-nav", classes.NavigationLeftBar, props.narrowHeader ? classes.ReducedBar : ''];
    if (leftItems.length === 0) {
        leftItemsClasses = [classes.NavigationLeftDisabled];
    }

    let rightItemsClasses = ["nav navbar-nav navbar-right", classes.NavigationRightBar, props.narrowHeader ? classes.ReducedBar : ''];
    if (!props.isToolbar) {
        rightItemsClasses = [classes.NavigationList];
    }

    return (
        <div className={classes.NavigationItems} onClick={props.clicked} style={{ height: props.narrowHeader ? '35px' : null }}>
            <ul className={leftItemsClasses.join(' ')}>
                {leftItems}
            </ul>
            {
                props.isToolbar ?
                    <div className={props.isSideDrawerOpened ? classes.MoveLogo : classes.FixedLogo}>
                        {
                            props.narrowHeader ?
                                <Zoom key={'nvg-reduced'}>
                                    <div className={classes.ReducedLogoContainer}>
                                        <div className={classes.ReducedLogoIcon}>
                                            <Logo src={narrowLogo} alt="Logo Reducido de Lo de Mario" link={props.authorized ? "/admin" : "/"} height={'47px'} />
                                        </div>
                                    </div>
                                </Zoom>
                                :
                                <Zoom key={'nvg-normal'}>
                                    <Logo src={loDeMarioLogo} alt="Logo Lo de Mario" link={props.authorized ? "/admin" : "/"} />
                                </Zoom>
                        }

                    </div>
                    :
                    null
            }
            <ul className={rightItemsClasses.join(' ')}>
                {rightItems}
            </ul>
        </div >
    );
};

export default NavigationItems;