import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    token: null,
    error: null,
    loading: false,
    authRedirectPath: '/admin/products',
    localStorageSelected: false
};

const authStart = (state) => {
    return updateObject(state, { error: null, loading: true });
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.idToken,
        error: null,
        loading: false
    });
}

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
}

const authLogout = (state) => {
    return updateObject(state, { token: null, localStorageSelected: true });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path });
}

const setConsultedLocalStorage = (state) => {
    return updateObject(state, { localStorageSelected: true});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SEND_EMAIL:
            return state;
        case actionTypes.AUTH_START:
            return authStart(state);

        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);

        case actionTypes.AUTH_FAIL:
            return authFail(state, action);

        case actionTypes.AUTH_LOGOUT:
            return authLogout(state);

        case actionTypes.SET_AUTH_REDIRECT_PATH:
            return setAuthRedirectPath(state, action);
        
        case actionTypes.CONSULTED_LOCAL_STORAGE:
            return setConsultedLocalStorage(state);

        default:
            return state;
    }
}

export default reducer;