import React from 'react';

import classes from './WelcomeScreen.module.css';
import Button from '../Button/Button';
import logo from '../../../assets/images/logoSpinnerRemembrance.png';
import backgroundImage from '../../../assets/images/backgroundPink.jpg';
import LazyBackground from '../../../hoc/LazyBackground/LazyBackground';
import LazyImage from '../LazyImage/LazyImage';
import Zoom from 'react-reveal/Zoom';

const WelcomeScreen = (props) => (
    <LazyBackground className={classes.BackgroundImage} src={backgroundImage} loader={true} >
        <Zoom bottom>
            <div className={classes.Frame}>
                <div className={classes.ImageContainer}>
                    <LazyImage alt={'Logo'} className={classes.Logo} src={logo} />
                </div>
                <h1 className={classes.Title}>Bienvenido</h1>
                <h1 className={classes.SubTitle}>Selecciona tu zona</h1>
                <div className={classes.ButtonsRow}>
                    {
                        props.areas && props.areas.map((area) => (
                            <Button
                                key={area.code}
                                className={classes.Button}
                                type="button"
                                btnType='Estandar'
                                clicked={() => props.clicked(area.code)}
                                disabled={props.buttonsDisabled}
                            >
                                {area.name}
                            </Button>
                        ))
                    }
                </div>
            </div>
        </Zoom>
    </LazyBackground>
);

export default WelcomeScreen;