import React from 'react';
import classes from './Spinner.module.css';

import loDeMarioLogo from '../../../assets/images/logoSpinner.png';

const spinner = (props) => (
    <div className={classes.SpinnerContainer}>
        <img className={classes.BlinkImage} alt="Spinner Logo" src={loDeMarioLogo} style={props.style}></img>
    </div>
);

export default spinner;