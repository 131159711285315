import React from 'react';
import classes from './DrawerToggle.module.css'

const drawerToggle = (props) => (
    <div className={classes.DrawerToggle}>
        <div
            className={props.narrowHeader ? classes.NarrowButton : classes.Button}
            onClick={props.clicked}>
            <i className="fa fa-bars"></i>
        </div>
    </div>
);

export default drawerToggle;