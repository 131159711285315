import * as actionTypes from './actionTypes';
import axios from '../../axios-firebase-DB';

export const fetchAvailabilitySuccess = (availability) => {
    return {
        type: actionTypes.FETCH_AVAILABILITY_SUCCESS,
        availability: availability
    };
};

export const fetchLocationsFail = (error) => {
    return {
        type: actionTypes.FETCH_AVAILABILITY_FAIL,
        error: error
    };
};

export const fetchAvailabilityStart = () => {
    return {
        type: actionTypes.FETCH_AVAILABILITY_START
    };
};

export const fetchAvailability = () => {
    return dispatch => {        
        dispatch(fetchAvailabilityStart());
        axios.get(`/availability.json`)
            .then(res => {
                dispatch(fetchAvailabilitySuccess(res.data));
            })
            .catch(err => {
                dispatch(fetchLocationsFail(err));
            });
    }
};