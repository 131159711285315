//AUTH
export const SEND_EMAIL = 'SEND_EMAIL';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const CONSULTED_LOCAL_STORAGE = 'CONSULTED_LOCAL_STORAGE';

//Emails
export const FETCH_EMAILS_START = 'FETCH_EMAILS_START';
export const FETCH_EMAILS_SUCCESS = 'FETCH_EMAILS_SUCCESS';
export const FETCH_EMAILS_FAIL = 'FETCH_EMAILS_FAIL';

//Products
export const FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAIL = 'FETCH_PRODUCTS_FAIL';

//Categories
export const FETCH_CATEGORIES_START = 'FETCH_CATEGORIES_START';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAIL = 'FETCH_CATEGORIES_FAIL';

//Flavours
export const FETCH_FLAVOURS_START = 'FETCH_FLAVOURS_START';
export const FETCH_FLAVOURS_SUCCESS = 'FETCH_FLAVOURS_SUCCESS';
export const FETCH_FLAVOURS_FAIL = 'FETCH_FLAVOURS_FAIL';

//Stores
export const FETCH_LOCATIONS_START = 'FETCH_LOCATIONS_START';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAIL = 'FETCH_LOCATIONS_FAIL';

//Cities
export const FETCH_CITIES_START = 'FETCH_CITIES_START';
export const FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS';
export const FETCH_CITIES_FAIL = 'FETCH_CITIES_FAIL';

//Areas
export const FETCH_AREAS_START = 'FETCH_AREAS_START';
export const FETCH_AREAS_SUCCESS = 'FETCH_AREAS_SUCCESS';
export const FETCH_AREAS_FAIL = 'FETCH_AREAS_FAIL';
export const SELECT_AREA = 'SELECT_AREA';

//Staff
export const FETCH_STAFF_START = 'FETCH_STAFF_START';
export const FETCH_STAFF_SUCCESS = 'FETCH_STAFF_SUCCESS';
export const FETCH_STAFF_FAIL = 'FETCH_STAFF_FAIL';

//Images
export const FETCH_IMAGES_START = 'FETCH_IMAGES_START';
export const FETCH_IMAGES_START_SAVING = 'FETCH_IMAGES_START_SAVING';
export const FETCH_IMAGES_SUCCESS = 'FETCH_IMAGES_SUCCESS';
export const FETCH_IMAGES_FAIL = 'FETCH_IMAGES_FAIL';

//MetaData
export const METADATA_TITLES_START = 'METADATA_TITLES_START';
export const METADATA_TITLES_SUCCESS = 'METADATA_TITLES_SUCCESS';
export const METADATA_TITLES_FAIL = 'METADATA_TITLES_FAIL';

//AdminFooter
export const EDIT_START = 'EDIT_START';
export const EDIT_SAVE_START = 'EDIT_SAVE_START';
export const EDIT_SAVE_SUCCESS = 'EDIT_SAVE_SUCCESS';
export const EDIT_SAVE_FAIL = 'EDIT_SAVE_FAIL';
export const EDIT_CANCEL = 'EDIT_CANCEL';

//Available
export const FETCH_AVAILABILITY_START = 'FETCH_AVAILABILITY_START';
export const FETCH_AVAILABILITY_SUCCESS = 'FETCH_AVAILABILITY_SUCCESS';
export const FETCH_AVAILABILITY_FAIL = 'FETCH_AVAILABILITY_FAIL';

export const ADD_START = 'ADD_START';
export const ADD_SAVE_START = 'ADD_SAVE_START';
export const ADD_SAVE_SUCCESS = 'ADD_SAVE_SUCCESS';
export const ADD_SAVE_FAIL = 'ADD_SAVE_FAIL';
export const ADD_CANCEL = 'ADD_CANCEL';