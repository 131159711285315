import * as firebase from 'firebase/app';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};


export const numberFormat = (value) =>
    new Intl.NumberFormat("de-DE").format(value);


export const checkValidity = (value, rules, listToCheckInclusion = []) => {
    let isValid = true;
    if (!rules) {
        return true;
    }

    if (rules.required) {
        isValid = value !== null && value.toString().trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.minValue) {
        isValid = value !== null && parseFloat(value[0] === '$' ? value.slice(1) : value) > 0 && isValid;
    }

    if(rules.isNotANumber){
        isValid = !isNaN(value) && isValid;
    }

    if (rules.includedInList) {
        isValid = !listToCheckInclusion.some(el =>{
            return el.name.replace(/^\s+|\s+$/g, '').trim() === value.replace(/^\s+|\s+$/g, '').trim();
        } 
            ) && isValid;
    }


    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid;
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid;
    }

    return isValid;
}        

export async function sendEmail(params) {
    firebase.firestore().collection('email').add({
        replyTo: params.replyTo,
        to: params.to,
        message: {
          subject: params.subject,
          text: params.text,
          attachments: params.attachments
        }
      }).then();
};