import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    emails: null,
    loading: false,
};

const fetchEmailsStart = (state) => {
    return updateObject(state, {
        emails: null,
        loading: true
    });
};

const fetchEmailsSuccess = (state, action) => {
    return updateObject(state, {
        emails: action.emails,
        loading: false
    });
};

const fetchEmailsFail = (state) => {
    return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.FETCH_EMAILS_START:
            return fetchEmailsStart(state);

        case actionTypes.FETCH_EMAILS_SUCCESS:
            return fetchEmailsSuccess(state, action);

        case actionTypes.FETCH_EMAILS_FAIL:
            return fetchEmailsFail(state);

        default:
            return state;
    }
};

export default reducer;