import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    products: null,
    flavours: null,
    categories: null,
    loading: false
};

const fetchProductsStart = (state, action) => {
    return updateObject(state, {
        products: action.isPartial ? state.products : null,
        loading: true
    });
};

const fetchProductsSuccess = (state, action) => {
    return updateObject(state, {
        products: action.isPartial ? state.products ? [...state.products, ...action.products] : action.products : action.products,
        loading: false
    });
};

const fetchProductsFail = (state) => {
    return updateObject(state, { loading: false });
};

const fetchCategoriesStart = (state) => {
    return updateObject(state, {
        categories: null,
        loading: true
    });
};

const fetchCategoriesSuccess = (state, action) => {
    return updateObject(state, {
        categories: action.categories,
        loading: false
    });
};

const fetchCategoriesFail = (state) => {
    return updateObject(state, { loading: false });
};

const fetchFlavoursStart = (state) => {
    return updateObject(state, {
        flavours: null,
        loading: true
    });
};

const fetchFlavoursSuccess = (state, action) => {
    return updateObject(state, {
        flavours: action.flavours,
        loading: false
    });
};

const fetchFlavoursFail = (state) => {
    return updateObject(state, { loading: false });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.FETCH_PRODUCTS_START:
            return fetchProductsStart(state, action);

        case actionTypes.FETCH_PRODUCTS_SUCCESS:
            return fetchProductsSuccess(state, action);

        case actionTypes.FETCH_PRODUCTS_FAIL:
            return fetchProductsFail(state);

        case actionTypes.FETCH_CATEGORIES_START:
            return fetchCategoriesStart(state);

        case actionTypes.FETCH_CATEGORIES_SUCCESS:
            return fetchCategoriesSuccess(state, action);

        case actionTypes.FETCH_CATEGORIES_FAIL:
            return fetchCategoriesFail(state);

        case actionTypes.FETCH_FLAVOURS_START:
            return fetchFlavoursStart(state);

        case actionTypes.FETCH_FLAVOURS_SUCCESS:
            return fetchFlavoursSuccess(state, action);

        case actionTypes.FETCH_FLAVOURS_FAIL:
            return fetchFlavoursFail(state);

        default:
            return state;
    }
};

export default reducer;