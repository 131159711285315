

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from './hoc/asyncComponent/asyncComponent';

const asyncAdmin = asyncComponent(() => {
    return import('./containers/Admin/Admin');
});

const asyncMainPage = asyncComponent(() => {
    return import('./containers/MainPage/MainPage');
});

const asyncProducts = asyncComponent(() => {
    return import('./containers/Admin/ProductsManager/ProductsManager');
});

const asyncFlavours = asyncComponent(() => {
    return import('./containers/Admin/FlavoursManager/FlavoursManager');
});

const asyncStaff = asyncComponent(() => {
    return import('./containers/Admin/StaffManager/StaffManager');
});

const asyncImages = asyncComponent(() => {
    return import('./containers/Admin/ImagesManager/ImagesManager');
});

const asyncStores = asyncComponent(() => {
    return import('./containers/Admin/StoresManager/StoresManager');
});

const asyncTitles = asyncComponent(() => {
    return import('./containers/Admin/TitlesManager/TitlesManager');
});

const asyncEmails = asyncComponent(() => {
    return import('./containers/Admin/EmailsManager/EmailsManager');
});

const asyncLogout = asyncComponent(() => {
    return import('./containers/Admin/Logout/Logout');
});

const asyncMenu = asyncComponent(() => {
    return import('./containers/Menu/Menu');
});

export const routesAuthenticated = (
    <Switch>
        <Route path="/admin/products" exact component={asyncProducts} />
        <Route path="/admin/flavours" exact component={asyncFlavours} />
        <Route path="/admin/images" exact component={asyncImages} />
        <Route path="/admin/stores" exact component={asyncStores} />
        <Route path="/admin/titles" exact component={asyncTitles} />
        <Route path="/admin/staff" exact component={asyncStaff} />
        <Route path="/admin/emails" exact component={asyncEmails} />
        <Route path="/admin/logout" exact component={asyncLogout} />
        <Route path="/admin" exact component={asyncAdmin} />
        <Route path="/" exact component={asyncMainPage} />
        <Redirect to="/" />
    </Switch>
)

export const routesNotAuthenticated = (
    <Switch>
        <Route path="/admin" exact component={asyncAdmin} />
        <Route path="/" exact component={asyncMainPage} />
        <Redirect to="/" />
    </Switch>
)

export const specialRoutes = (
    <Switch>
        <Route path="/menu" exact component={asyncMenu} />
        <Redirect to="/" />
    </Switch>
)