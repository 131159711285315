import * as actionTypes from './actionTypes';
import axios from '../../axios-firebase-DB';
import * as firebase from 'firebase';

const fetchImagesSuccess = (images, section) => {
    return {
        type: actionTypes.FETCH_IMAGES_SUCCESS,
        images: section !== '' ? {
            [section]: images[section]
        } : images,
        section: section
    };
};

const fetchImagesFail = (error) => {
    return {
        type: actionTypes.FETCH_IMAGES_FAIL,
        error: error
    };
};

const fetchImagesStart = () => {
    return {
        type: actionTypes.FETCH_IMAGES_START
    };
};

const fetchImagesStartSaving = () => {
    return {
        type: actionTypes.FETCH_IMAGES_START_SAVING
    };
};

export const fetchImages = (section = '') => {
    return dispatch => {
        dispatch(fetchImagesStart());
        const sectionString = section ? `/${section}` : '';
        axios.get(`/images${sectionString}.json`)
            .then(res => {
                dispatch(fetchImagesSuccess(section ? {
                    [section]: res.data} : res.data, section));
            })
            .catch(err => {
                dispatch(fetchImagesFail(err));
            });
    }
};

export const saveImage = (newImage, section, sectionImages, imageIndex, token) => {
    return dispatch => {
        sectionImages[imageIndex] = {
            name: sectionImages[imageIndex].name,
            url: newImage.url
        }
        const requestBody = {
            [section]: sectionImages
        };
        axios.patch('/images.json?auth=' + token, requestBody)
            .then(res => {
                dispatch(fetchImagesSuccess(res.data, section));
            })
            .catch(err => {
                dispatch(fetchImagesFail(err));
            });
    }
};

export const saveImages = (newImage, section, sectionImages, imageIndex, token) => {
    return dispatch => {
        dispatch(fetchImagesStartSaving());
        const storageRef = firebase.storage().ref(`images/${section}/${imageIndex + 1}-${newImage.name}`);
        const task = storageRef.put(newImage);
        task.on('state_changed', () => {
        }, (error) => {
            dispatch(fetchImagesFail(error.message));
        }, () => {
            task.snapshot.ref.getDownloadURL().then((downloadURL) => {
                newImage.url = downloadURL;
                dispatch(saveImage(newImage, section, sectionImages, imageIndex, token));
            });
        });
    }
}

export const removeImage = (section, sectionImages, token) => {
    return dispatch => {
        dispatch(fetchImagesStartSaving());
        const requestBody = {
            [section]: sectionImages
        };
        axios.patch('/images.json?auth=' + token, requestBody)
            .then(res => {
                dispatch(fetchImagesSuccess(res.data, section));
            })
            .catch(err => {
                dispatch(fetchImagesFail(err));
            });
    }
}


export const deleteImage = (image, section, sectionImages, token) => {
    return dispatch => {
        dispatch(fetchImagesStartSaving());
        const storageRef = firebase.storage().refFromURL(image.url);
        storageRef.delete().then(() => {
            dispatch(removeImage(section, sectionImages, token));
        }).catch((error) => {
            dispatch(fetchImagesFail(error.message));
        });
    }
}