import * as actionTypes from './actionTypes';
import * as firebase from 'firebase';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    return dispatch => {
        firebase.auth().signOut().then(() => {
            dispatch({
                type: actionTypes.AUTH_LOGOUT
            })
        });
    };
};

export const auth = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            email: email,
            password: password,
            returnSecureToken: true
        };
        firebase
            .auth()
            .signInWithEmailAndPassword(authData.email, authData.password)
            .then(res => {
                if (res.user) {
                    res.user.getIdToken().then((token) => {
                        dispatch(authSuccess(token));
                    }).catch(
                        error => { 
                            dispatch(authFail(error.code)) ;
                        }
                    );
                }
            })
            .catch(error => {
                dispatch(authFail(error.code)) ;
            });
    };
};

export const consultedLocalStorage = () => {
    return {
        type: actionTypes.CONSULTED_LOCAL_STORAGE
    }
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    if (token) {
                        dispatch(authSuccess(token));
                    } else {
                        dispatch(logout());
                    }
                }).catch(() => {
                    dispatch(logout());
                }).finally(() => {
                    dispatch(consultedLocalStorage());
                });
            } else {
                dispatch(logout());
                dispatch(consultedLocalStorage());
            }
        });
    };
};