import React, { Component } from 'react';
import Spinner from '../Spinner/Spinner';
class LazyImage extends Component {
    state = { src: null };

    componentDidMount() {
        const { src } = this.props;

        const imageLoader = new Image();
        imageLoader.src = src;

        imageLoader.onload = () => {
            this.setState({ src });
        };
    }

    render() {
        let data = null;
        if (this.state.src) {
            data = <img className={this.props.className} alt={this.props.alt} src={this.state.src} />;
        } else if (!this.state.src && this.props.loader) {
            data = <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%'
            }}>
                <Spinner style={{ height: this.props.loaderSize, width: this.props.loaderSize }} />
            </div>;
        } else {
            data = <div {...this.props} />;
        }
        return data;
    }
}

export default LazyImage;