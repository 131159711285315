import React from 'react';

import classes from './SocialBar.module.css';
import * as constUrl from '../../../constants';
import Zoom from 'react-reveal/Zoom';

const socialBar = () => (
    <nav className={classes.SocialBar}>
        <Zoom key={'sob-social-network'} cascade delay={500}>
            <div className={classes.SocialNetworkContainer} >
                <a href={constUrl.facebookURL} target="_blank" rel="noopener noreferrer" className={classes.SNElemFacebook} tabIndex="-1">
                    <i className="fab fa-facebook-f"></i>
                </a>
                <a href={constUrl.whatsappURL} target="_blank" rel="noopener noreferrer" className={classes.SNElemWhatsapp} tabIndex="-1">
                    <i className="fab fa-whatsapp"></i>
                </a>
                <a href={constUrl.instagramURL} target="_blank" rel="noopener noreferrer" className={classes.SNElemInstagram} tabIndex="-1">
                    <i className="fab fa-instagram"></i>
                </a>
            </div>
        </Zoom>
    </nav>
);

export default socialBar;