import * as actionTypes from './actionTypes';
import axios from '../../axios-firebase-DB';
import * as firebase from 'firebase';

export const fetchStaffSuccess = (staff) => {
    return {
        type: actionTypes.FETCH_STAFF_SUCCESS,
        staff: staff.staff,
        count: staff.count
    };
};

export const fetchStaffFail = (error) => {
    return {
        type: actionTypes.FETCH_STAFF_FAIL,
        error: error
    };
};

export const fetchStaffStart = (partial) => {
    return {
        type: actionTypes.FETCH_STAFF_START,
        isPartial: partial
    };
};

export const fetchStaff = (startAt, endAt) => {
    return dispatch => {
        if(!endAt) {
            dispatch(fetchStaffStart());
        }
        Promise.all([
            !endAt ? axios.get('/staff/count.json') : new Promise((resolve) => resolve(0)),
            axios.get(startAt && !endAt ? `/staff/items.json?orderBy="$value"&limitToFirst=${startAt}` : startAt && endAt ? `/staff/items.json?orderBy="$value"&limitToLast=${endAt - startAt}` : '/staff/items.json')
        ]).then(res => {
                dispatch(fetchStaffSuccess({
                    count: res[0].data ?? 0,
                    staff: res[1].data.filter(staff => !!staff)
                }));
            })
            .catch(err => {
                dispatch(fetchStaffFail(err));
            });
    }
};

export const saveSingleImageStaff = (newImage, index, staff, token) => {
    return dispatch => {
        dispatch(fetchStaffStart(true));
        const staffElem = staff[index];
        const storageRef = firebase.storage().ref(`images/staff/0${index}-${staffElem.name}`);
        const task = storageRef.put(newImage);
        task.on('state_changed', () => {
        }, (error) => {
            dispatch(fetchStaffFail(error.message));
        }, () => {
            task.snapshot.ref.getDownloadURL().then((downloadURL) => {
                staffElem.url = downloadURL;
                axios.put('/staff.json?auth=' + token, { items: staff, count: staff.length })
                .then(response => {
                    dispatch(fetchStaffSuccess(staff));
                })
                .catch(error => {
                    dispatch(fetchStaffFail());
                });
            });
        });
    }
}

export const deleteSingleImageStaff = (image, index, staff, token) => {
    return dispatch => {
        dispatch(fetchStaffStart(true));
        const newStaff = staff.map((staffElem, indexElem) => {
            if(indexElem === index) {
                delete staffElem['url'];
            }
            return staffElem;
        })
        const storageRef = firebase.storage().refFromURL(image.url);
        storageRef.delete().then(() => {
            axios.put(`/staff.json?auth=` + token, { items: newStaff, count: newStaff.length })
                .then(response => {
                    dispatch(fetchStaffSuccess(newStaff));
                })
                .catch(error => {
                    dispatch(fetchStaffFail());
                });
        }).catch((error) => {
            dispatch(fetchStaffFail(error.message));
        });
    }
}