import React from 'react';

// import Logo from '../../components/Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import classes from './SideDrawer.module.css';
import * as constUrl from '../../../constants';


const sideDrawer = (props) => {
    let attachedClasses = [classes.SideDrawer, classes.Close];
    if (props.open) {
        attachedClasses = [classes.SideDrawer, classes.Open];
    }
    return (
        <Auxiliary>
            <div className={attachedClasses.join(' ')}>
                <div className={classes.Cross} >
                    <i onClick={props.closed} className="fa fa-times"></i>
                </div>

                <NavigationItems menuOptions={props.menuOptions} isToolbar={false} clicked={props.closed} />
                <div className={classes.SocialNetworkContainer} >
                    <a href={constUrl.facebookURL} target="_blank" rel="noopener noreferrer" className={classes.SNElemFacebook} tabIndex="-1">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href={constUrl.whatsappURL} target="_blank" rel="noopener noreferrer" className={classes.SNElemWhatsapp} tabIndex="-1">
                        <i className="fab fa-whatsapp"></i>
                    </a>
                    <a href={constUrl.instagramURL} target="_blank" rel="noopener noreferrer" className={classes.SNElemInstagram} tabIndex="-1">
                        <i className="fab fa-instagram"></i>
                    </a>
                </div>
            </div >
        </Auxiliary >
    );
};

export default sideDrawer;