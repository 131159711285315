import Swal from 'sweetalert2';

// Firebase config
export const firebaseProdConfig = {
    apiKey: "AIzaSyBjBUJnvkufgMryuNLlrfaQa915zTTQ9O4",
    authDomain: "lodemario-72b93.firebaseapp.com",
    databaseURL: "https://lodemario-72b93.firebaseio.com",
    projectId: "lodemario-72b93",
    storageBucket: "lodemario-72b93.appspot.com",
    messagingSenderId: "188206753668"
};

// export const firebaseDevConfig = {
//     databaseURL: "https://lodemario-dev.firebaseio.com/",
//     authDomain: "lodemario-dev.firebaseapp.com",
//     apiKey: "AIzaSyDv_s3algZm8A-sFvzeHj6guCWxheflhXo",
//     projectId: "lodemario-dev",
//     storageBucket: "lodemario-dev.appspot.com",
//     messagingSenderId: "668720348539"
// };


export const facebookURL = "https://www.facebook.com/SanwicheriasLoDeMario";
export const instagramURL = "https://www.instagram.com/lodemario.oficial/?hl=es-la";
export const twitterURL = "https://twitter.com/lodemariomdq";
export const whatsappURL = 'https://api.whatsapp.com/send?phone=542236341465';

export const swalCustom = Swal.mixin({
    customClass: {
        confirmButton: 'btn-swal2',
        cancelButton: 'btn-swal2',
        container: 'container-swal2',
        popup: 'popup-swal2',
        title: 'title-swal2',
        image: 'image-swal2',
        content: 'content-swal2'
    },
    buttonsStyling: false
});