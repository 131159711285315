import * as actionTypes from './actionTypes';
import axios from '../../axios-firebase-DB';
import { extractBasicDataItems, extractSubItemDataItems } from './index';
import * as firebase from 'firebase';

export const fetchProductsSuccess = (products, partial) => {
    return {
        type: actionTypes.FETCH_PRODUCTS_SUCCESS,
        isPartial: partial,
        products: products
    };
};

export const fetchProductsFail = (error) => {
    return {
        type: actionTypes.FETCH_PRODUCTS_FAIL,
        error: error
    };
};

export const fetchProductsStart = (partial) => {
    return {
        type: actionTypes.FETCH_PRODUCTS_START,
        isPartial: partial
    };
};

export const fetchProducts = (category, startAt, endAt) => {
    if (category) {
        return dispatch => {
            dispatch(fetchProductsStart(true));
            axios.get(startAt && !endAt ? `/products/products/${category}.json?orderBy="$value"&limitToFirst=${startAt}` : startAt && endAt ? `/products/products/${category}.json?orderBy="$value"&limitToLast=${endAt - startAt}` : `/products/products/${category}.json`)
                .then(res => {
                    const data = extractBasicDataItems(res.data).map((product) => ({
                        ...product,
                        parent_id: category                        
                    })); 
                    dispatch(fetchProductsSuccess(data, !!category));
                })
                .catch(err => {
                    dispatch(fetchProductsFail(err));
                });
        } 
    }

    return dispatch => {
        dispatch(fetchProductsStart());
        axios.get('/products/products.json')
            .then(res => {
                dispatch(fetchProductsSuccess(extractSubItemDataItems(res.data)));
            })
            .catch(err => {
                dispatch(fetchProductsFail(err));
            });
    }
};

export const fetchCategoriesSuccess = (categories) => {
    return {
        type: actionTypes.FETCH_CATEGORIES_SUCCESS,
        categories: categories
    };
};

export const fetchCategoriesFail = (error) => {
    return {
        type: actionTypes.FETCH_CATEGORIES_FAIL,
        error: error
    };
};

export const fetchCategoriesStart = () => {
    return {
        type: actionTypes.FETCH_CATEGORIES_START
    };
};

export const fetchCategories = () => {
    return dispatch => {
        dispatch(fetchCategoriesStart());
        axios.get('/products/categories.json')
            .then(res => {
                dispatch(fetchCategoriesSuccess(extractBasicDataItems(res.data)));
            })
            .catch(err => {
                dispatch(fetchCategoriesFail(err));
            });
    }
};

export const fetchFlavoursSuccess = (flavours) => {
    return {
        type: actionTypes.FETCH_FLAVOURS_SUCCESS,
        flavours: flavours
    };
};

export const fetchFlavoursFail = (error) => {
    return {
        type: actionTypes.FETCH_FLAVOURS_FAIL,
        error: error
    };
};

export const fetchFlavoursStart = () => {
    return {
        type: actionTypes.FETCH_FLAVOURS_START
    };
};

export const fetchFlavours = () => {
    return dispatch => {
        dispatch(fetchFlavoursStart());
        axios.get('/flavours.json')
            .then((flavoursRes) => {
                const flavoursList = flavoursRes.data.map((flavour, index) => {
                    return {
                        code: index,
                        name: flavour
                    }
                });
                dispatch(fetchFlavoursSuccess(flavoursList));
            })
            .catch(err => {
                dispatch(fetchFlavoursFail(err));
            });
    }
};

export const saveSingleImageProduct = (newImage, product, category, id, products, token) => {
    return dispatch => {
        dispatch(fetchProductsStart(true));
        const storageRef = firebase.storage().ref(`images/products/${category}/${id}-${product.name}`);
        const task = storageRef.put(newImage);
        task.on('state_changed', () => {
        }, (error) => {
            dispatch(fetchProductsFail(error.message));
        }, () => {
            task.snapshot.ref.getDownloadURL().then((downloadURL) => {
                product.url = downloadURL;
                axios.put(`/products/products/${category}/${id}.json?auth=` + token, product)
                .then(response => {
                    dispatch(fetchProductsSuccess(products.map(product => product.id === id ? {...product, url: response.data.url} : product)));
                })
                .catch(error => {
                    dispatch(fetchProductsFail());
                });
            });
        });
    }
}

export const deleteSingleImageProduct = (image, product, category, id, products, token) => {
    return dispatch => {
        dispatch(fetchProductsStart(true));
        const storageRef = firebase.storage().refFromURL(image.url);
        storageRef.delete().then(() => {
            axios.put(`/products/products/${category}/${id}.json?auth=` + token, product)
                .then(response => {
                    dispatch(fetchProductsSuccess(products.map(product => product.id === id ? {...product, url: ''} : product)));
                })
                .catch(error => {
                    dispatch(fetchProductsFail());
                });
        }).catch((error) => {
            dispatch(fetchProductsFail(error.message));
        });
    }
}