import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    editing: false,
    adding: false,
    saving: false
};


const editStart = (state) => {
    return updateObject(state, { editing: true });
};

const editSaveStart = (state) => {
    return updateObject(state, { saving: true });
};

const editSaveSuccess = (state) => {
    return updateObject(state, { editing: false, saving: false, loading: true });
};

const editSaveFail = (state) => {
    return updateObject(state, { editing: true, saving: false });
};

const editCancel = (state) => {
    return updateObject(state, { editing: false, saving: false });
};

const addStart = (state) => {
    return updateObject(state, { adding: true });
};

const addSaveStart = (state) => {
    return updateObject(state, { saving: true });
};

const addSaveSuccess = (state) => {
    return updateObject(state, { adding: false, saving: false, loading: true });
};

const addSaveFail = (state) => {
    return updateObject(state, { adding: true, saving: false });
};

const addCancel = (state) => {
    return updateObject(state, { adding: false, saving: false });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.EDIT_START:
            return editStart(state);

        case actionTypes.EDIT_SAVE_START:
            return editSaveStart(state);

        case actionTypes.EDIT_SAVE_SUCCESS:
            return editSaveSuccess(state);

        case actionTypes.EDIT_SAVE_FAIL:
            return editSaveFail(state);

        case actionTypes.EDIT_CANCEL:
            return editCancel(state);

        case actionTypes.ADD_START:
            return addStart(state);

        case actionTypes.ADD_SAVE_START:
            return addSaveStart(state);

        case actionTypes.ADD_SAVE_SUCCESS:
            return addSaveSuccess(state);

        case actionTypes.ADD_SAVE_FAIL:
            return addSaveFail(state);

        case actionTypes.ADD_CANCEL:
            return addCancel(state);

        default:
            return state;
    }
};

export default reducer;