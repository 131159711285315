import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    locations: null,
    areas: null,
    cities: null,
    selectedArea: null,
    loading: false
};


const fetchLocationsStart = (state, action) => {
    return updateObject(state, {
        locations: action.isPartial ? state.locations : null,
        loading: true
    });
};

const fetchLocationsSuccess = (state, action) => {
    return updateObject(state, {
        locations: action.isPartial ? state.locations ? [...state.locations, ...action.locations] : action.locations : action.locations,
        loading: false
    });
};

const fetchLocationsFail = (state) => {
    return updateObject(state, { loading: false });
};

const fetchCitiesStart = (state) => {
    return updateObject(state, {
        cities: null,
        loading: true
    });
};

const fetchCitiesSuccess = (state, action) => {
    return updateObject(state, {
        cities: action.cities,
        loading: false
    });
};

const fetchCitiesFail = (state) => {
    return updateObject(state, { loading: false });
};

const fetchAreasStart = (state) => {
    return updateObject(state, {
        areas: null,
        loading: true
    });
};

const fetchAreasSuccess = (state, action) => {
    return updateObject(state, {
        areas: action.areas,
        loading: false
    });
};

const fetchAreasFail = (state) => {
    return updateObject(state, { loading: false });
};

const selectArea = (state, action) => {
    return updateObject(state, {
        selectedArea: action.selectedArea
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.FETCH_LOCATIONS_START:
            return fetchLocationsStart(state, action);

        case actionTypes.FETCH_LOCATIONS_SUCCESS:
            return fetchLocationsSuccess(state, action);

        case actionTypes.FETCH_LOCATIONS_FAIL:
            return fetchLocationsFail(state);

        case actionTypes.FETCH_CITIES_START:
            return fetchCitiesStart(state);

        case actionTypes.FETCH_CITIES_SUCCESS:
            return fetchCitiesSuccess(state, action);

        case actionTypes.FETCH_CITIES_FAIL:
            return fetchCitiesFail(state);

        case actionTypes.FETCH_AREAS_START:
            return fetchAreasStart(state);

        case actionTypes.FETCH_AREAS_SUCCESS:
            return fetchAreasSuccess(state, action);

        case actionTypes.FETCH_AREAS_FAIL:
            return fetchAreasFail(state);

        case actionTypes.SELECT_AREA:
            return selectArea(state, action);

        default:
            return state;
    }
};

export default reducer;