import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { routesAuthenticated, routesNotAuthenticated, specialRoutes } from './routes';

import Layout from './hoc/Layout/Layout';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';
import Auxiliary from './hoc/Auxiliary/Auxiliary';

class App extends Component {
    componentDidMount() {
        this.props.onTryAutoSignUp();
    }

    render() {
        let routes = null;
        let isMenu = false;
        if (this.props.localStorageSelected) {
            routes = routesNotAuthenticated;
        }
        if (this.props.isAuthenticated) {
            routes = routesAuthenticated;
        }

        if (this.props.location.pathname === '/menu') {
            isMenu = true;
            routes = specialRoutes;
        }

        return (
            <Auxiliary>
                {
                    isMenu ?
                        <Auxiliary>
                            {routes}
                        </Auxiliary>
                        :
                        <Layout selectedTab={this.props.location.pathname}>
                            {routes}
                        </Layout>
                }
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        localStorageSelected: state.auth.localStorageSelected
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignUp: () => dispatch(actions.authCheckState())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));