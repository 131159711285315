import * as actionTypes from './actionTypes';
import axios from '../../axios-firebase-DB';

export const titlesStart = () => {
    return {
        type: actionTypes.METADATA_TITLES_START
    };
};

export const titlesSuccess = (titles, subElement) => {
    return {
        type: actionTypes.METADATA_TITLES_SUCCESS,
        titles: subElement ?
            {
                [subElement]: titles
            } : titles,
        subElement: subElement
    };
};

export const titlesFail = (error) => {
    return {
        type: actionTypes.METADATA_TITLES_FAIL,
        error: error
    };
};

export const fetchTitles = (subElement) => {
    return dispatch => {
        dispatch(titlesStart());     
        axios.get(subElement ? `/metadata/titles/${subElement}.json` : `/metadata/titles.json`)
            .then(res => {
                dispatch(titlesSuccess(res.data, subElement));
            })
            .catch(err => {
                dispatch(titlesFail(err.message));
            });
    }
};
