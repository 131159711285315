import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    titles: null,
    loading: false,
    error: null
};

const fetchMetadataTStart = (state) => {
    return updateObject(state, {
        loading: true,
        error: null
    });
};

const fetchMetadataTSuccess = (state, titles, subElement) => {
    return updateObject(state, {
        titles: subElement ? {
            ...state.titles,
            [subElement]: titles[subElement]
        } : titles,
        loading: false
    });
};

const fetchMetadataTFail = (state) => {
    return updateObject(state, {
        loading: false,
        error: null
    });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.METADATA_TITLES_START:
            return fetchMetadataTStart(state);

        case actionTypes.METADATA_TITLES_SUCCESS:
            return fetchMetadataTSuccess(state, action.titles, action.subElement);

        case actionTypes.METADATA_TITLES_FAIL:
            return fetchMetadataTFail(state, action);

        default:
            return state;
    }
};

export default reducer;
