import React from 'react';

import classes from './Logo.module.css';
import LazyImage from '../../UI/LazyImage/LazyImage';

const logo = (props) => (
    <div className={classes.Logo}>
        <a href={props.link} tabIndex="-1">
            <LazyImage src={props.src} alt={props.alt} style={{ height: props.height }} />
        </a>
    </div>
);

export default logo;