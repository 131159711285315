import React, { Component } from 'react';
import classes from './NavigationItem.module.css'
import { HashLink as Link } from 'react-router-hash-link';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

class navigationItem extends Component {

    navigateToMenu = () => {
        if (this.props.link[0] === '/') {
            this.props.history.push(this.props.link);
        } else {
            let element = document.getElementById(this.props.link);
            window.scrollTo({
                top: element ? element.offsetTop - (isMobile ? 60 : 30) : 0,
                behavior: "smooth"
            });
        }
    }

    render() {
        let navigationClass = classes.NavigationItem;
        if (!this.props.isToolbar) {
            navigationClass = classes.SidebarMode;
        }

        return (
            <li className={navigationClass}>
                <Link onClick={this.navigateToMenu} to="#" tabIndex="-1">
                    {this.props.children}
                </Link >
            </li>
        );
    }
};

export default withRouter(navigationItem);