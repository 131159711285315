import React, { Component } from 'react';

import classes from './Layout.module.css';

import Auxiliary from '../Auxiliary/Auxiliary';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../../components//Navigation/SideDrawer/SideDrawer';
import SocialBar from '../../components/UI/SocialBar/SocialBar';
import EditionFooter from '../../components/UI/EditionFooter/EditionFooter';
import WelcomeScreen from '../../components/UI/WelcomeScreen/WelcomeScreen';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Zoom from 'react-reveal/Zoom';
import TemporarlyUnavailableScreen from '../../components/UI/TemporarilyUnavailableScreen/TemporarilyUnavailableScreen';
class Layout extends Component {
    state = {
        showSideDrawer: false,
        menuOptions: {
            admin: [
                {
                    label: "Productos",
                    link: "/admin/products",
                    onLeft: true,
                    hasFooter: true,
                    canAddNewItem: true
                },
                {
                    label: "Variedades",
                    link: "/admin/flavours",
                    onLeft: true,
                    hasFooter: true,
                    canAddNewItem: true
                },
                {
                    label: "Staff",
                    link: "/admin/staff",
                    onLeft: true,
                    hasFooter: true,
                    canAddNewItem: true
                },
                {
                    label: "Pagina",
                    link: "/",
                    onLeft: true,
                    hasFooter: false,
                    canAddNewItem: false
                },
                {
                    label: "Imagenes",
                    link: "/admin/images",
                    onLeft: false,
                    hasFooter: false,
                    canAddNewItem: false
                },
                {
                    label: "Sucursales",
                    link: "/admin/stores",
                    onLeft: false,
                    hasFooter: true,
                    canAddNewItem: true
                },
                {
                    label: "Titulos",
                    link: "/admin/titles",
                    onLeft: false,
                    hasFooter: true,
                    canAddNewItem: false
                },
                {
                    label: "Emails",
                    link: "/admin/emails",
                    onLeft: false,
                    hasFooter: false,
                    canAddNewItem: false
                },
                {
                    label: "Logout",
                    link: "/admin/logout",
                    onLeft: false,
                    hasFooter: false,
                    canAddNewItem: false
                }
            ],
            mainPage: [
                {
                    label: "Inicio",
                    link: "page-top",
                    onLeft: true,
                    hasFooter: false,
                    canAddNewItem: false
                },
                {
                    label: "Ofertas",
                    link: "sales",
                    onLeft: true,
                    hasFooter: false,
                    canAddNewItem: false
                },
                {
                    label: "Productos",
                    link: "products",
                    onLeft: true,
                    hasFooter: false,
                    canAddNewItem: false
                },
                {
                    label: "Sucursales",
                    link: "stores",
                    onLeft: false,
                    hasFooter: false,
                    canAddNewItem: false
                },
                {
                    label: "Staff",
                    link: "staff",
                    onLeft: false,
                    hasFooter: false,
                    canAddNewItem: false
                },
                {
                    label: "Historia",
                    link: "about",
                    onLeft: false,
                    hasFooter: false,
                    canAddNewItem: false
                },
                {
                    label: "Contacto",
                    link: "contactUs",
                    onLeft: false,
                    hasFooter: false,
                    canAddNewItem: false
                }
            ]
        },
        showButtonToTop: false,
        narrowHeader: false
    }

    checkScrollTop = () => {
        const windowHeight = window.visualViewport.heigh - 140 > 700 ? window.visualViewport.heigh - 140 : 700;
        if ((!this.state.showButtonToTop || !this.state.narrowHeader) && window.pageYOffset + windowHeight > 1.5 * windowHeight) {
            this.setState({ showButtonToTop: true });
            this.setState({ narrowHeader: true });
        } else if ((!this.state.narrowHeader || this.state.narrowHeader) && window.pageYOffset + windowHeight <= 1.5 * windowHeight) {
            this.setState({ showButtonToTop: false });
            this.setState({ narrowHeader: false });
        }
    };

    scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    sideDrawerClosedHandler = () => {
        this.setState({
            showSideDrawer: false
        });
    }

    sideDrawerToogleHandler = () => {
        this.setState((prevState) => {
            return { showSideDrawer: !prevState.showSideDrawer }
        });
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        window.addEventListener('scroll', this.checkScrollTop);
        this.resize();
        if (!this.props.selectedArea) {
            this.props.onFetchAreasToSelecting();
        }
        if(this.props.availability === null) {
            this.props.onFetchAvailability();
        }
    }

    resize() {
        if (window.innerWidth > 1000 && this.state.showSideDrawer) {
            this.setState({ showSideDrawer: false });
        }
    }

    handlerAreaClick = (areaId) => {
        this.props.onSetSelectedArea(areaId);
    }

    render() {
        let attachedClasses = [classes.Content, classes.Close, this.state.narrowHeader ? classes.NarrowHeader : ''];
        if (this.state.showSideDrawer) {
            attachedClasses = [classes.Content, classes.Open, this.state.narrowHeader ? classes.NarrowHeader : ''];
        }

        let showSideDrawerClass = classes.Close;
        if (this.state.showSideDrawer) {
            showSideDrawerClass = classes.Open;
        }
        let socialPage = <SocialBar />;
        let menuOptions = this.state.menuOptions['mainPage'];
        let editionFooter = null;
        if (this.props.selectedTab !== '/' && this.props.selectedTab !== '/admin') {
            if (this.props.token) {
                menuOptions = this.state.menuOptions['admin'];
            } else {
                menuOptions = [];
            }
            socialPage = null;
            const element = menuOptions.find(elem => elem.link === this.props.selectedTab);
            if (element && element.hasFooter) {
                editionFooter = <EditionFooter className={showSideDrawerClass} addButtonDisplayed={element.canAddNewItem} />;
            }
        }
        const login = this.props.selectedTab === '/admin' || this.props.selectedTab === '/admin/';
        if (login) {
            return (
                <main className={attachedClasses.join(' ')}>
                    {this.props.children}
                </main>
            );
        } else {
            return (
                <Auxiliary>
                    {
                        this.props.availability !== null && !this.props.availability ? 
                            <TemporarlyUnavailableScreen />
                            : null
                    }
                    {
                        this.props.availability && this.props.selectedArea === null && this.props.areas && this.props.selectedTab === '/' ?
                            <WelcomeScreen
                                areas={this.props.areas}
                                clicked={(code) => { this.handlerAreaClick(code) }}
                                buttonsDisabled={this.props.selectedArea}
                            />
                            : null
                    }
                    {
                        this.props.availability && (this.props.selectedArea !== null || this.props.selectedTab !== '/') ?
                            <Auxiliary>
                                {
                                    menuOptions.length ?
                                        <Toolbar
                                            className={showSideDrawerClass}
                                            menuOptions={menuOptions}
                                            isSideDrawerOpened={this.state.showSideDrawer}
                                            drawerToggleClicked={this.sideDrawerToogleHandler}
                                            authorized={this.props.token}
                                            narrowHeader={this.state.narrowHeader && menuOptions[0]?.link === "page-top"}
                                        />
                                        : null
                                }
                                <main className={attachedClasses.join(' ')}>
                                    {this.props.children}
                                </main>
                                <SideDrawer
                                    menuOptions={menuOptions}
                                    open={this.state.showSideDrawer}
                                    closed={this.sideDrawerClosedHandler} />
                                {socialPage}
                                {editionFooter}
                            </Auxiliary>
                            : null
                    }
                    {
                        this.props.availability && !this.state.showSideDrawer && menuOptions[0]?.link === "page-top" ?
                            <Zoom key={'lyt-footer'} when={this.state.showButtonToTop} right>
                                <div className={classes.ButtonToTop} onClick={this.scrollTop}>
                                    <div className={classes.ButtonElement}>
                                        <span className={'fa fa-angle-up'} />
                                    </div>
                                </div>
                            </Zoom> : null
                    }
                </Auxiliary>
            );
        }

    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        selectedArea: state.stores.selectedArea,
        areas: state.stores.areas,
        availability: state.availability.availability
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchAreasToSelecting: () => dispatch(actions.fetchAreasToSelecting()),
        onFetchAvailability: () => dispatch(actions.fetchAvailability()),
        onSetSelectedArea: (areaId) => dispatch(actions.setSelectedArea(areaId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);