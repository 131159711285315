import React, { Component } from 'react';

import classes from './EditionFooter.module.css';
import Button from '../Button/Button';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';

class EditionFooter extends Component {


    render() {

        let cancelButton = null;
        let addNewItemButton = this.props.addButtonDisplayed ? <Button type="button" className={classes.Button} clicked={this.props.onAddStart} btnType='Estandar'>Agregar</Button> : null;
        let newElementButton = <Button type="button" className={classes.Button} clicked={this.props.onEditStart} btnType='Estandar'>Editar</Button>;
        if (this.props.editing) {
            addNewItemButton = null;
            cancelButton = <Button type="button" className={classes.Button} clicked={this.props.onEditCancel} btnType='Estandar'>Cancelar</Button>;
            newElementButton = <Button type="button" className={classes.Button} disabled={this.props.saving} clicked={this.props.onEditSaveStart} btnType='Estandar'>Guardar</Button>;
        }


        if (this.props.adding) {
            addNewItemButton = null;
            cancelButton = <Button type="button" className={classes.Button} clicked={this.props.onAddCancel} btnType='Estandar'>Cancelar</Button>;
            newElementButton = <Button type="button" className={classes.Button} disabled={this.props.saving} clicked={this.props.onAddSaveStart} btnType='Estandar'>Guardar</Button>;
        }

        const buttonClasses = [classes.ButtonContainer, this.props.className];
        return (
            <footer className={classes.EditionFooter}>
                <div className={buttonClasses.join(' ')}>
                    {addNewItemButton}
                    {cancelButton}
                    {newElementButton}
                </div>
            </footer>
        );
    }
};

const mapStateToProps = state => {
    return {
        editing: state.admin.editing,
        adding: state.admin.adding,
        saving: state.admin.saving
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditStart: () => dispatch(actions.editStart()),
        onEditSaveStart: () => dispatch(actions.editSaveStart()),
        onEditCancel: () => dispatch(actions.editCancel()),
        onAddStart: () => dispatch(actions.addStart()),
        onAddSaveStart: () => dispatch(actions.addSaveStart()),
        onAddCancel: () => dispatch(actions.addCancel())
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(EditionFooter);