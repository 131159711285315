import * as actionTypes from './actionTypes';
import axios from '../../axios-firebase-DB';
// import { extractBasicDataItems, extractSubItemDataItems } from './index';
import { extractBasicDataItems } from './index';

export const fetchEmailsSuccess = (emails) => {
    return {
        type: actionTypes.FETCH_EMAILS_SUCCESS,
        emails: emails
    };
};

export const fetchEmailsFail = (error) => {
    return {
        type: actionTypes.FETCH_EMAILS_FAIL,
        error: error
    };
};

export const fetchEmailsStart = () => {
    return {
        type: actionTypes.FETCH_EMAILS_START
    };
};

export const fetchEmails = () => {
    return dispatch => {
        dispatch(fetchEmailsStart());
        axios.get('/emails.json')
            .then((res) => {
                dispatch(fetchEmailsSuccess(Array.from((new Set(extractBasicDataItems(res.data).map(email => email.email))).values())));
            })
            .catch(err => {
                dispatch(fetchEmailsFail(err));
            });
    }
};
