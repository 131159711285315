import * as actionTypes from './actionTypes';
import axios from '../../axios-firebase-DB';
import * as firebase from 'firebase';

export const editStart = () => {
    return {
        type: actionTypes.EDIT_START
    };
};

export const editSaveStart = () => {
    return {
        type: actionTypes.EDIT_SAVE_START
    };
};

export const editCancel = () => {
    return {
        type: actionTypes.EDIT_CANCEL
    };
};

export const addStart = () => {
    return {
        type: actionTypes.ADD_START
    };
};

export const addSaveStart = () => {
    return {
        type: actionTypes.ADD_SAVE_START
    };
};

export const addCancel = () => {
    return {
        type: actionTypes.ADD_CANCEL
    };
};

export const saveSingleProduct = (product, category, token) => {
    return dispatch => {
        Promise.all(
            [
                axios.put(`/products/categories/${category.id}.json?auth=${token}`, {
                    name: category.name,
                    count: category.count
                }),
                axios.post(`/products/products/${category.name}.json?auth=${token}`, product)
            ]
        ).then(() => {
            dispatch(editSaveSuccess());
        }).catch(() => {
            dispatch(editSaveFail());
        });
    }
}

export const addSaveSuccess = () => {
    return {
        type: actionTypes.ADD_SAVE_SUCCESS
    };
};

export const addSaveFail = () => {
    return {
        type: actionTypes.ADD_SAVE_FAIL
    };
};


export const saveSingleFlavour = (newFlavourValue, flavoursList, token) => {
    return dispatch => {
        let requestBody = [];
        flavoursList.forEach((flavour) => {
            requestBody.push(flavour.name);
        });
        requestBody = [...requestBody, newFlavourValue];
        axios.put('/flavours.json?auth=' + token, requestBody)
            .then(response => {
                dispatch(addSaveSuccess());
            })
            .catch(error => {
                dispatch(addSaveFail());
            });
    }
}

export const saveAllFlavours = (flavoursList, deletedFlavours, token) => {
    return dispatch => {
        let dataToSave = 0;
        if (flavoursList.length) {
            dataToSave = flavoursList;
        }
        if (deletedFlavours.length) {
            axios.get('/products.json')
                .then(products => {
                    Object.keys(products.data).forEach((productCategory) => {
                        products.data[productCategory] = products.data[productCategory].map((product) => {
                            if (product.flavour) {
                                if (deletedFlavours.indexOf(product.flavour) > -1) {
                                    product.flavour = null;
                                } else {
                                    const substrahendValue = deletedFlavours.filter(elem => elem < product.flavour).length;
                                    product.flavour -= substrahendValue;
                                }
                            }
                            return product;
                        });
                    });
                    axios.put('/products.json?auth=' + token, products.data)
                        .then(response => {
                            axios.put('/flavours.json?auth=' + token, dataToSave)
                                .then(response => {
                                    dispatch(editSaveSuccess());
                                })
                                .catch(error => {
                                    dispatch(editSaveFail());
                                });
                        })
                        .catch(error => {
                            dispatch(editSaveFail());
                        });
                })
        } else {
            axios.put('/flavours.json?auth=' + token, dataToSave)
                .then(response => {
                    dispatch(editSaveSuccess());
                })
                .catch(error => {
                    dispatch(editSaveFail());
                });
        }
    }
}

export const editSaveSuccess = () => {
    return {
        type: actionTypes.EDIT_SAVE_SUCCESS
    };
};

export const editSaveFail = () => {
    return {
        type: actionTypes.EDIT_SAVE_FAIL
    };
};


export const saveAllProducts = (productsList, token) => {
    return dispatch => {
        axios.put('/products.json?auth=' + token, productsList)
            .then(response => {
                dispatch(editSaveSuccess());
            })
            .catch(error => {
                dispatch(editSaveFail());
            });
    }
}

export const saveStores = (locationList, token) => {
    return dispatch => {
        Promise.all(
            [
                axios.put('/stores/areas.json?auth=' + token, locationList.areas),
                axios.put(`/stores/cities.json?auth=` + token, Object.assign({}, ...locationList.cities.map(city => 
                    ({
                        [city.id]: {
                            name: city.name,
                            count: locationList.locations.filter(location => location.parent_id === city.name).length
                        }
                    })))),
                axios.put(`/stores/locations.json?auth=` + token, Object.assign({}, ...transformLocations(locationList)))
            ]
        )
            .then(response => {
                dispatch(editSaveSuccess());
            })
            .catch(error => {
                dispatch(editSaveFail());
            });
    }
}

const transformLocations = (locationList) => {
    return Array.from(new Set(locationList.locations.map(location => location.parent_id))).map(cityName => {
        const locationObject = {
            [cityName]: {}
        };
        locationList.locations.filter(location => location.parent_id === cityName).forEach((location) => 
            locationObject[cityName] = {
                ...locationObject[cityName],
                [location.id] : {
                    name: location.name,
                    description: location.description,
                    geolocation: location.geolocation,
                    image: location?.image,
                    url: location.url
                }
            }
        );
        return locationObject;
    });
}

export const saveSingleCategory = (category, token) => {
    return dispatch => {
        axios.post('/products/categories.json?auth=' + token, category)
            .then(() => {
                dispatch(addSaveSuccess());
            })
            .catch(() => {
                dispatch(addSaveFail());
            });
    }
}

export const saveSingleCity = (city, token) => {
    return dispatch => {
        axios.post('/stores/cities.json?auth=' + token, city)
            .then(() => {
                dispatch(addSaveSuccess());
            })
            .catch(() => {
                dispatch(addSaveFail());
            });
    }
}

export const saveSingleStore = (store, city, token) => {
    return dispatch => {
        Promise.all(
            [
                axios.put(`/stores/cities/${city.id}.json?auth=${token}`, {
                    name: city.name,
                    count: city.count
                }),
                axios.post(`/stores/locations/${city.name}.json?auth=${token}`, store)
            ]
        ).then(() => {
            dispatch(addSaveSuccess());
        }).catch(() => {
            dispatch(addSaveFail());
        });
    }
}

export const saveAllTitles = (titles, token) => {
    return dispatch => {
        axios.put('/metadata/titles.json?auth=' + token, titles)
            .then(res => {
                dispatch(editSaveSuccess(res.data));
            })
            .catch(error => {
                dispatch(editSaveFail(error.message));
            });
    }
}

export const saveSingleStaff = (newStaffValue, staffList, token) => {
    return dispatch => {
        let requestBody = [];
        requestBody = [...staffList, newStaffValue];
        axios.put('/staff.json?auth=' + token, { items: requestBody, count: requestBody.length })
            .then(response => {
                dispatch(addSaveSuccess());
            })
            .catch(error => {
                dispatch(addSaveFail());
            });
    }
}

export const saveAllStaff = (staffList, token) => {
    return dispatch => {
        axios.put('/staff.json?auth=' + token, {items: staffList, count: staffList.length})
            .then(response => {
                dispatch(editSaveSuccess());
            })
            .catch(error => {
                dispatch(editSaveFail());
            });
    }
}

export const saveEmailData = (email) => {
    return dispatch => {
        axios.post('/emails.json', email)
            .then(res => {
                dispatch(editSaveSuccess(res.data));
            })
            .catch(error => {
                dispatch(editSaveFail(error.message));
            });
    }
}