import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    images: null,
    loading: false,
};

const fetchImagesStart = (state) => {
    return updateObject(state, {
        loading: true
    });
};

const fetchImagesStartSaving = (state) => {
    return updateObject(state, {
        loading: true
    });
};

const fetchImagesSuccess = (state, newImages, section) => {
    return updateObject(state, {
        images: section ? {
            ...state.images,
            [section]: newImages[section]
        } : newImages,
        loading: false
    });
};

const fetchImagesFail = (state) => {
    return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.FETCH_IMAGES_START:
            return fetchImagesStart(state);

        case actionTypes.FETCH_IMAGES_START_SAVING:
            return fetchImagesStartSaving(state);

        case actionTypes.FETCH_IMAGES_SUCCESS:
            return fetchImagesSuccess(state, action.images, action.section);

        case actionTypes.FETCH_IMAGES_FAIL:
            return fetchImagesFail(state);

        default:
            return state;
    }
};

export default reducer;