import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    staff: null,
    count: null,
    loading: false
};


const fetchStaffStart = (state, action) => {
    return updateObject(state, {
        ...(action.isPartial ? {
            staff: state.staff,
            count: state.staff.length
        } : {
            staff: null,
            count: null
        }),
        loading: true
    });
};

const fetchStaffSuccess = (state, action) => {
    return updateObject(state, {
        staff: !state.count ? action.staff : [...state.staff, ... action.staff] ,
        ...(!state.count ? {
            count: action.count
        } : undefined),
        loading: false
    });
};

const fetchStaffFail = (state) => {
    return updateObject(state, { loading: false });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.FETCH_STAFF_START:
            return fetchStaffStart(state, action);

        case actionTypes.FETCH_STAFF_SUCCESS:
            return fetchStaffSuccess(state, action);

        case actionTypes.FETCH_STAFF_FAIL:
            return fetchStaffFail(state);

        default:
            return state;
    }
};

export default reducer;