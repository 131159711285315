import React from 'react';

import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToogle from '../SideDrawer/DrawerToggle/DrawerToggle';
import classes from './Toolbar.module.css';

const Toolbar = (props) => {
    const headerClasses = ["navbar navbar-custom navbar-fixed-top", classes.Toolbar, props.narrowHeader ? classes.NarrowHeader : classes.NormalHeader];
    return (
        <header className={headerClasses.join(' ')}>
            <NavigationItems
                isSideDrawerOpened={props.isSideDrawerOpened}
                menuOptions={props.menuOptions}
                isToolbar={true}
                authorized={props.authorized}
                narrowHeader={props.narrowHeader} />
            <div className={props.className}>
                <DrawerToogle clicked={props.drawerToggleClicked} narrowHeader={props.narrowHeader}/>
            </div>
        </header>
    );
};

export default Toolbar;