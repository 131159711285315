export default { extractBasicDataItems, extractSubItemDataItems };

export function extractBasicDataItems(data) {
    return Object.keys(data).map(key => 
        ({
            ...data[key],
            id: key
        }));
};

export function extractSubItemDataItems(data) {
    const auxArray = [];
    Object.keys(data).map(key_parent => 
        Object.keys(data[key_parent]).map(key_child =>
            auxArray.push({
            ...data[key_parent][key_child],
            parent_id: key_parent,
            id: key_child
        })));
    return auxArray;
};

export {
    fetchProducts,
    fetchCategories,
    fetchFlavours,
    saveSingleImageProduct,
    deleteSingleImageProduct
} from './products';

export {
    fetchLocations,
    fetchAreas,
    fetchCities,
    setSelectedArea,
    fetchAreasToSelecting,
    saveSingleImageLocation,
    deleteSingleImageLocation
} from './stores'

export {
    fetchStaff,
    saveSingleImageStaff,
    deleteSingleImageStaff
} from './staff'

export {
    editStart,
    editSaveStart,
    editCancel,
    addStart,
    addSaveStart,
    addCancel,
    saveSingleProduct,
    saveSingleCategory,
    saveSingleFlavour,
    saveSingleCity,
    saveSingleStore,
    saveAllProducts,
    addSaveFail,
    editSaveFail,
    saveAllFlavours,
    saveStores,
    saveAllTitles,
    saveSingleStaff,
    saveAllStaff,
    saveEmailData
} from './admin';
export {
    auth,
    logout,
    setAuthRedirectPath,
    authCheckState

} from './auth';
export {
    fetchImages,
    saveImages,
    deleteImage
} from './images';
export {
    fetchTitles
} from './meta-data';
export {
    fetchEmails
} from './emails';
export {
    fetchAvailability
} from './availability';